import styled from "styled-components";

//3rd party
import { Button, Form } from "antd"
import { Plans } from "./PartnerPlans";
import { useAppSelector } from "../hooks";

export const PlanForm = styled(BasePlanForm)`
`

interface IPlanForm {
  className?: string,
  onDone?: (action: "save" | "prev" | "next") => void,
}

export function BasePlanForm({className, onDone}: IPlanForm) {

  const subscriptionPlan = useAppSelector((state) => state.partner.subscriptionPlan)

  function handlePrev() {
    if(onDone) onDone("prev")
  }

  function handleNext(e: any) {
    //Remove focus to prevent user clicking 'enter' button to progress forward
    if(e?.target) {
      e.target.blur()
    }

    if(onDone) onDone("next")
  }

  return (
    <div className={`${className} partner-form`}>
      <Plans onboardingForm={true} />
      <Form.Item key={1} className="form-buttons">
        <Button size="large" className="left-btn" onClick={handlePrev}>
          Back
        </Button>
        <Button disabled={!subscriptionPlan} size="large" className="right-btn" onClick={handleNext}>
          Next
        </Button>
      </Form.Item>
    </div>
  )
}
