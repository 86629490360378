import { useAppSelector, useIsPortrait } from "../hooks"

//3rd party components
import { IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonToolbar } from "@ionic/react"
import { pieChartOutline } from "ionicons/icons"
import moment from "moment";
import { Card, Divider } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Plans } from "./PartnerPlans";

import './Plan.scss';

const COMMISSON_FEE = 0.075 // This is the fee we charge partner services

export function Plan() {

  const isPortrait = useIsPortrait()

  //Redux states
  const trialEndDate = useAppSelector((state) => state.partner.trialEndDate ? moment(state.partner.trialEndDate) : undefined)
  const isRestaurant = useAppSelector((state) => state.partner.details?.isRestaurant)

  function getCommissionRate() {
    if(trialEndDate && moment() < trialEndDate) {
      return 0
    } else {
      return COMMISSON_FEE
    }
  }

  function getTrialEndDate() {
    if(trialEndDate && moment() < trialEndDate) {
      return <p>Your <b>Free Trial</b> plan will end on {trialEndDate.format("LL")}</p>
    }
  }

  function getCommissionFee() {
    if(trialEndDate && moment() < trialEndDate) {
      return (
        <div className="future-rates">
          <span><InfoCircleOutlined /> {`After ${trialEndDate.format("LL")} your commission fee will increase to ${COMMISSON_FEE * 100}%`}</span>
        </div>
      )
    }
  }

  return (
    <IonPage className="plan">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/main/settings" />
          </IonButtons>
          <div className="page-header">
            <IonIcon icon={pieChartOutline} />
            <span className="page-header-text">Plan Details</span>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="plan-container">
          <div className={`plan-details ${isPortrait ? 'portrait' : 'landscape'}`}>
            <Plans />
            {
              !isRestaurant &&
              <>
                <Divider />
                <Card title="Tifyn App Plan">
                  <span className="subheading">Current Commission Rate</span>
                  <p className="commission-rate">{getCommissionRate() * 100}%</p>
                  {getTrialEndDate()} 
                  {getCommissionFee()}
                </Card>
              </>
            }
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}
