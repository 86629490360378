import { useEffect, useState } from "react";
import styled from "styled-components";

//3rd party
import { SubscriptionPlan } from "@tiffin/core"
import { PlanCard } from "../components/PlanCard"
import { cloudFunction, partnerHelper, partnerReducer } from "@tiffin/app-common";
import { SpringModal, toast } from "@tiffin/components";
import { useIonToast, useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { App, AppState } from '@capacitor/app';
import { Alert } from "antd";

export const Plans = styled(BasePlans)`
  &.plan-form {
    display: flex;
    gap: 10px;

    @media (max-width:768px)  {
      flex-direction: column;
    }
  }
`

interface IPlanForm {
  className?: string,
  onboardingForm?: boolean
}

export function BasePlans({className, onboardingForm}: IPlanForm) {

  const partnerUrl = useAppSelector((state) => state.partner.urlName)
  const subscriptionPlan = useAppSelector((state) => state.partner.subscriptionPlan)

  const [ isActive, setIsActive ] = useState(true)
  const [ switchPlan, setSwitchPlan ] = useState<SubscriptionPlan | null>(null);
  const [ loadingPlan, setLoadingPlan ] = useState<SubscriptionPlan>()

  const [present] = useIonToast();
  const dispatch = useAppDispatch()

  useIonViewDidEnter(() => {
    App.addListener('appStateChange', (state: AppState) => {
      setIsActive(state.isActive)
    });
  }, [])

  useIonViewDidLeave(() => {
    App.removeAllListeners()
  })

  useEffect(() => {
    if(isActive) {
      partnerHelper.getPartnerInfo()
      .then((val: any) => {
        console.log(val)
        dispatch(partnerReducer.setSubscriptionPlan({
          plan: val.subscriptionPlan,
        }))
      })
    }
  }, [isActive, dispatch])
  
  const manageSubscription = (plan: SubscriptionPlan, action: "create" | "update" | "upgrade") => {
    setLoadingPlan(plan)
    cloudFunction("manageBasicSubscription", {action, onboarding: onboardingForm ?? false, plan: plan }).then((result: any) => {
      setLoadingPlan(undefined)
      if(result.data.status === "success") {
        dispatch(partnerReducer.setSubscriptionPlan({
          plan: plan,
        }))

        if(action === "upgrade") {
          toast(present, "Successfully switched plan", "success")
        } else {
          if(result.data.session.url) {
            window.location.href = result.data.session.url
          }
        }
      } else {
        toast(present, "An unexpected error occurred", "danger")
      }
    })
  }
  
  const switchSubscriptionPlan = () => {
    if(switchPlan) {
      manageSubscription(switchPlan, "upgrade")
      setSwitchPlan(null)
    }
  }

  const handlePlanChange = (newPlan: SubscriptionPlan) => {
    if(subscriptionPlan === undefined) {
      manageSubscription(newPlan, "create")
    } else if(subscriptionPlan !== newPlan) {
      setSwitchPlan(newPlan);
    } else {
      manageSubscription(newPlan, "update")
    }
  }

  const planHeading = () => {
    if(switchPlan === SubscriptionPlan.StarterMonthly) {
      return 'Starter monthly'
    } else if(switchPlan === SubscriptionPlan.StarterAnnual) {
      return 'Starter annual'
    } else if(switchPlan === SubscriptionPlan.OnlineOrderingMonthly) {
      return 'Online Ordering Monthly'
    } else if(switchPlan === SubscriptionPlan.OnlineOrderingAnnual) {
      return 'Online rdering annual'
    }
  }

  return (
    <div className={`${className} plan-form`}>
      <PlanCard 
        title="Starter"
        subTitle="Enhance your online presence with a customised visually enticing website and interactive menus"
        price={1900}
        frequency={"annually"}
        features={[
          'Your very own store URL',
          'Customised Website',
          'Digital Menu',
          'Menu categories and groups'
        ]}
        onChange={handlePlanChange}
        plan={SubscriptionPlan.StarterAnnual}
        loadingPlan={loadingPlan}
      />
      <PlanCard 
        title="Online Ordering"
        subTitle="Increase your customer reach with flexible online ordering, an enticing website and easy to use owners portal to easily manage your store."
        price={1900}
        frequency={"monthly"}
        features={[
          'Your very own store URL',
          'Customised Website',
          'No joining fee or setup costs',
          'Digital Menu',
          'Menu categories and groups',
          'Item modifiers',
          'Pickup and delivery orders',
          'Scheduled pre-orders',
          'New order email alerts',
          'Apple Pay and Google Pay integrations',
          'Same day payouts',
          '2% per order processing fee'
        ]}
        onChange={handlePlanChange}
        plan={SubscriptionPlan.OnlineOrderingMonthly}
        loadingPlan={loadingPlan}
      />
      <PlanCard 
        title="Online Ordering (Annual Saver)"
        subTitle="Increase your customer reach with flexible online ordering, an enticing website and easy to use owners portal to easily manage your store."
        price={19900}
        frequency={"annually"}
        features={[
          'Your very own store URL',
          'Customised Website',
          'No joining fee or setup costs',
          'Digital Menu',
          'Menu categories and groups',
          'Item modifiers',
          'Pickup and delivery orders',
          'Scheduled pre-orders',
          'New order email alerts',
          'Apple Pay and Google Pay integrations',
          'Same day payouts',
          '2% per order processing fee'
        ]}
        onChange={handlePlanChange}
        plan={SubscriptionPlan.OnlineOrderingAnnual}
        loadingPlan={loadingPlan}
      />
      <SpringModal
        isOpen={!!switchPlan}
        onCancel={() => {
          setSwitchPlan(null)
        }}
        okText="Confirm"
        onOk={switchSubscriptionPlan}
        title="Switch plans"
      >
        <p>Are you sure you want to switch to the <b>{planHeading()}</b> plan?</p>
        <Alert style={{fontSize: 'smaller'}} showIcon type="info" message="If you are switching to a lower plan, you will receive a pro-rata credit which will be used for future repayments" />
      </SpringModal>
    </div>
  )
}
