import styled from "styled-components";
import { useState } from "react";
import pack from "../../package.json";
import { userReducer } from "@tiffin/app-common";
import { useAppDispatch, useAppSelector } from "../hooks";

//Components
import { Browser } from "@capacitor/browser";
import { SpringModal } from "@tiffin/components";
import { IonContent, IonIcon, IonPage, useIonRouter } from "@ionic/react"
import { calendarOutline, carOutline, closeCircleOutline, downloadOutline, globeOutline, helpCircleOutline, logOutOutline, personOutline, pieChartOutline, playCircleOutline, readerOutline, restaurantOutline, settingsOutline, storefrontOutline, timeOutline, walletOutline } from 'ionicons/icons';
import { PageBreadcrumb } from "../components/PageBreadcrumb";
import { SubscriptionPlan } from "@tiffin/core";

const PARTNER_FAQ_LINK = "https://tifyn.com.au/partner-help"

export const SettingsMenu = styled(BaseSettingsMenu)`
  height: 100%;

  ion-content {
    --background: var(--ion-color-light);
  }

  .main-content {
    padding-top: 10px;
  }

  .edit-menu-option {
    font-weight: 400;
    padding: 20px;
    background-color: #fff;
    border-bottom: 1px solid var(--ion-color-light);

    display: flex;
    align-items: center;
    gap: 10px;

    ion-icon {
      font-size: medium;
    }
  }
  
  .edit-menu-option:active {
    background-color: var(--ion-color-light-shade);
  }

  .version-tag {
    text-align: right;
    color: var(--ion-color-medium);
    padding: 10px 20px;
  }
`

interface ISettingsMenuProps {
  className?: string
}

export function BaseSettingsMenu({className}: ISettingsMenuProps) {

  const subscriptionPlan = useAppSelector((state) => state.partner.subscriptionPlan)
  const partnerStatus = useAppSelector((state) => state.partner.partnerStatus)
  const partnerIsRestaurant = useAppSelector((state) => state.partner.details?.isRestaurant)
  const partnerPlan = useAppSelector((state) => state.partner.subscriptionPlan)

  const [signoutModalVisible, setSignoutModalVisible] = useState<boolean>(false)
  const [signingOut, setSigningOut] = useState(false) 

  const dispatch = useAppDispatch()
  const router = useIonRouter()

  function renderSignoutConfirmation() {
    return (
      <SpringModal 
        isOpen={signoutModalVisible} 
        onOk={handleSignOut} 
        onCancel={() => {if(!signingOut) setSignoutModalVisible(false)}} 
        okText="Yes"
        title={"Sign out"}
      >
        Are you sure you want to sign out?
      </SpringModal>
    )
  }

  function handleSignOut() {
    setSigningOut(true)
    dispatch(userReducer.signOutUser("partner"))
  }
  
  return (
    <IonPage className={className}>
      <IonContent>
        <div className="page-container">
          <div className="page-breadcrumb-header">
            <PageBreadcrumb
              items={[
                {
                  title: <><IonIcon icon={settingsOutline} /> Settings</>,
                }
              ]}
            />
          </div>
          <div className="main-content">
            <div
              onClick={() => {
                router.push("/account/info")
              }}
              className="edit-menu-option"
            >
              <IonIcon icon={personOutline} />
              <span>Account</span>
            </div>
            <div
              onClick={() => {
                router.push("/plan")
              }}
              className="edit-menu-option"
            >
              <IonIcon icon={pieChartOutline} />
              <span>Plan</span>
            </div>
            <div
              onClick={() => {
                router.push("/edit/details")
              }}
              className="edit-menu-option"
            >
              <IonIcon icon={readerOutline} />
              <span>Details</span>
            </div>
            {
              subscriptionPlan &&
              <div
                onClick={() => {
                  router.push("/edit/websiteoptions")
                }}
                className="edit-menu-option"
              >
                <IonIcon icon={globeOutline} />
                <span>Website options</span>
              </div>
            }
            <div
              onClick={() => {
                router.push("/edit/advance")
              }}
              className="edit-menu-option"
            >
              <IonIcon icon={timeOutline} />
              <span>Preparation Times</span>
            </div>
            <div
              onClick={() => {
                router.push("/edit/store")
              }}
              className="edit-menu-option"
            >
              <IonIcon icon={storefrontOutline} />
              <span>Store Options</span>
            </div>
            {
              partnerIsRestaurant &&
              <div
                onClick={() => {
                  router.push("/edit/dinein")
                }}
                className="edit-menu-option"
              >
                <IonIcon icon={restaurantOutline} />
                <span>Dine In</span>
              </div>
            }
            <div
              onClick={() => {
                router.push("/edit/delivery")
              }}
              className="edit-menu-option"
            >
              <IonIcon icon={carOutline} />
              <span>Delivery</span>
            </div>
            <div
              onClick={() => {
                router.push("/edit/available-dates")
              }}
              className="edit-menu-option"
            >
              <IonIcon icon={calendarOutline} />
              <span>Available Dates</span>
            </div>
            {
              (partnerPlan && partnerPlan > 0) &&
              <div
                onClick={() => {
                  router.push("/resources")
                }}
                className="edit-menu-option"
              >
                <IonIcon icon={downloadOutline} />
                <span>Resources</span>
              </div>
            }
            <div
              onClick={() => {
                router.push("/edit/payment")
              }}
              className="edit-menu-option"
            >
              <IonIcon icon={walletOutline} />
              <span>Payment</span>
            </div>
            <div
              onClick={() => {
                router.push("/account/status")
              }}
              className="edit-menu-option"
            >
              <IonIcon icon={partnerStatus === 'active' ? closeCircleOutline : playCircleOutline} />
              <span>{partnerStatus === 'active' ? 'Deactivate' : 'Activate'}</span>
            </div>
            <div
              onClick={() => {
                Browser.open({ url: PARTNER_FAQ_LINK });
              }}
              className="edit-menu-option"
            >
              <IonIcon icon={helpCircleOutline} />
              <span>Help</span>
            </div>
            <div onClick={() => setSignoutModalVisible(true)} 
              className="edit-menu-option"
            >
              <IonIcon icon={logOutOutline} />
              <span>Sign Out</span>
            </div>
            <div className="version-tag">{pack.version}</div>
          </div>
        </div>
        {renderSignoutConfirmation()}
      </IonContent>
    </IonPage>
  )
}
