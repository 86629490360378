import React from "react";
import moment from "moment";
import { AdvanceDurationType, Day, cutoffDate, OrderType, DateFormat, OrderStatus } from "@tiffin/core";
import { Constants } from "./constants";
import { getOrderDays } from "./partnerUtils";
import { getRouteInfo } from "./firebase-helpers/delivery";
export var MenuView;
(function (MenuView) {
    MenuView["Groups"] = "Groups";
    MenuView["Days"] = "Days";
})(MenuView || (MenuView = {}));
export var StoreOpenStatus;
(function (StoreOpenStatus) {
    StoreOpenStatus[StoreOpenStatus["Open"] = 1] = "Open";
    StoreOpenStatus[StoreOpenStatus["Closed"] = 2] = "Closed";
    StoreOpenStatus[StoreOpenStatus["PausedToday"] = 3] = "PausedToday";
    StoreOpenStatus[StoreOpenStatus["NotAcceptingOrders"] = 4] = "NotAcceptingOrders";
})(StoreOpenStatus || (StoreOpenStatus = {}));
export var Utils = /** @class */ (function () {
    function Utils() {
    }
    //Map utility functions
    Utils.haversineDistanceInKm = function (pos1, pos2) {
        var R = 6371.071; // Radius of the Earth in miles
        var rlat1 = pos1.lat * (Math.PI / 180); // Convert degrees to radians
        var rlat2 = pos2.lat * (Math.PI / 180); // Convert degrees to radians
        var difflat = rlat2 - rlat1; // Radian difference (latitudes)
        var difflon = (pos2.lng - pos1.lng) * (Math.PI / 180); // Radian difference (longitudes)
        var d = 2 *
            R *
            Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
        return d;
    };
    Utils.parseAddress = function (location, coords) {
        var address = {};
        for (var _i = 0, _a = location === null || location === void 0 ? void 0 : location.address_components; _i < _a.length; _i++) {
            var component = _a[_i];
            if (component.types.includes("subpremise")) {
                address.subpremise = component.long_name;
            }
            else if (component.types.includes("street_number")) {
                address.streetNumber = component.long_name;
            }
            else if (component.types.includes("route")) {
                address.street = component.long_name;
            }
            else if (component.types.includes("locality")) {
                address.locality = component.long_name;
            }
            else if (component.types.includes("administrative_area_level_1")) {
                address.state = component.short_name;
            }
            else if (component.types.includes("country")) {
                address.country = component.long_name;
            }
            else if (component.types.includes("postal_code")) {
                address.postalCode = component.long_name;
            }
        }
        address.placeId = location.place_id;
        if (coords) {
            address.coords = coords;
        }
        return address;
    };
    Utils.formatAddress = function (address, hideExact) {
        var formatted = "";
        if (!hideExact && address.subpremise) {
            formatted += "".concat(address.subpremise, "/");
        }
        if (!hideExact && address.streetNumber) {
            formatted += "".concat(address.streetNumber, " ");
        }
        if (address.street) {
            formatted += "".concat(address.street, ",");
        }
        formatted += " ".concat(address.locality, " ").concat(address.state);
        return formatted;
    };
    Utils.checkCoverImage = function (file) {
        var error = "";
        var isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            error = "You can only upload JPG/PNG file";
        }
        var isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            error = "Image must smaller than 2MB";
        }
        return {
            valid: isJpgOrPng && isLt2M,
            error: error
        };
    };
    Utils.getDayString = function (day) {
        if (day === Day.Monday) {
            return "Monday";
        }
        else if (day === Day.Tuesday) {
            return "Tuesday";
        }
        else if (day === Day.Wednesday) {
            return "Wednesday";
        }
        else if (day === Day.Thursday) {
            return "Thursday";
        }
        else if (day === Day.Friday) {
            return "Friday";
        }
        else if (day === Day.Saturday) {
            return "Saturday";
        }
        else if (day === Day.Sunday) {
            return "Sunday";
        }
        else {
            return "";
        }
    };
    Utils.getRelativeDayString = function (day) {
        if (day.clone().startOf("day").isSame(moment().startOf("day"))) {
            //Today
            return "Today";
        }
        else if (day.clone().startOf("day").isSame(moment().startOf("day").add(1, "day"))) {
            return "Tomorrow";
        }
        else {
            return Utils.getDayString(day.day());
        }
    };
    Utils.getRelativeDateString = function (day, format) {
        if (day.clone().startOf("day").isSame(moment().startOf("day"))) {
            //Today
            return "Today";
        }
        else if (day.clone().startOf("day").isSame(moment().startOf("day").add(1, "day"))) {
            return "Tomorrow";
        }
        else {
            return day.format(format !== null && format !== void 0 ? format : 'D MMMM');
        }
    };
    Utils.getNextOrderDay = function (orderType, orderDays, preparationEnabled, preparationDurationType, preparationDuration, adjustedDates, pauseDates, preparationInMins) {
        var _a, _b;
        //order next available day
        var currDay = moment().startOf("day");
        while (true) {
            if (orderDays[currDay.day()] && (orderType === OrderType.DineIn || !(pauseDates === null || pauseDates === void 0 ? void 0 : pauseDates.includes(currDay.format(DateFormat))))) {
                // Get adjusted date
                var adjustedDate = orderType === OrderType.Pickup || orderType === OrderType.DineIn ?
                    (_a = adjustedDates === null || adjustedDates === void 0 ? void 0 : adjustedDates[currDay.format(DateFormat)]) === null || _a === void 0 ? void 0 : _a.pickupTimings :
                    (_b = adjustedDates === null || adjustedDates === void 0 ? void 0 : adjustedDates[currDay.format(DateFormat)]) === null || _b === void 0 ? void 0 : _b.deliveryTimings;
                if (moment() < cutoffDate(currDay, orderDays[currDay.day()], preparationEnabled, preparationDurationType, preparationDuration, adjustedDate, preparationInMins)) {
                    return currDay;
                }
            }
            currDay.add(1, "day");
        }
    };
    Utils.getAvailableDaysInOrder = function (nextOrderDay, orderDays, advanceOrderDays) {
        var currDate = nextOrderDay.clone();
        var days = [];
        if (currDate) {
            //Calculate days available for next week
            var endDay = moment().add(advanceOrderDays !== null && advanceOrderDays !== void 0 ? advanceOrderDays : Constants.DEFAULT_ADVANCE_ORDER_DAYS, "days");
            while (currDate < endDay) {
                if (orderDays[currDate.day()]) {
                    days.push(currDate.clone());
                }
                currDate.add(1, "day");
            }
        }
        return days;
    };
    Utils.checkDeliveryAvailable = function (storeLocation, customerLocation, deliveryConfiguration) {
        var _a;
        var maxDistanceMeters = (_a = deliveryConfiguration === null || deliveryConfiguration === void 0 ? void 0 : deliveryConfiguration.baseDistanceMeters) !== null && _a !== void 0 ? _a : 0;
        if (deliveryConfiguration === null || deliveryConfiguration === void 0 ? void 0 : deliveryConfiguration.tiers) {
            for (var _i = 0, _b = deliveryConfiguration === null || deliveryConfiguration === void 0 ? void 0 : deliveryConfiguration.tiers; _i < _b.length; _i++) {
                var tier = _b[_i];
                maxDistanceMeters += tier.distanceMeters;
            }
        }
        return new Promise(function (resolve) {
            getRouteInfo(storeLocation, customerLocation)
                .then(function (routeInfo) {
                if (routeInfo.routes.length > 0) {
                    if (routeInfo.routes[0].distanceMeters <= maxDistanceMeters) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                }
                else {
                    resolve(false);
                }
            });
        });
    };
    Utils.getCutoffDescription = function (advanceOptions, navigate) {
        return (React.createElement("span", null, "Cut off times are automatically calculated based on your selection of ",
            " ",
            React.createElement("span", { onClick: function () {
                    if (navigate)
                        navigate(1);
                }, className: "cutoff-desc-advance-duration" },
                advanceOptions.preparationDurationType === AdvanceDurationType.Hours &&
                    "".concat(advanceOptions.preparationDuration, " ").concat(advanceOptions.preparationDuration === 1 ? "hour" : "hours"),
                advanceOptions.preparationDurationType === AdvanceDurationType.Days &&
                    "".concat(advanceOptions.preparationDuration, " ").concat(advanceOptions.preparationDuration === 1 ? "day" : "days")),
            " ", " advance order"));
    };
    Utils.getAdvanceOrderText = function (preparationDuration, preparationDurationType) {
        if (preparationDuration === undefined || preparationDurationType === undefined) {
            return;
        }
        var advanceOrder = "";
        advanceOrder = "Orders must be placed  ";
        if (preparationDurationType === AdvanceDurationType.Days) {
            if (preparationDuration === 1) {
                advanceOrder += "".concat(preparationDuration, " day");
            }
            else {
                advanceOrder += "".concat(preparationDuration, " days");
            }
        }
        else if (preparationDurationType === AdvanceDurationType.Hours) {
            if (preparationDuration < 1) {
                advanceOrder += "".concat(preparationDuration * 60, " minutes");
            }
            else if (preparationDuration === 1) {
                advanceOrder += "".concat(preparationDuration, " hour");
            }
            else {
                advanceOrder += "".concat(preparationDuration, " hours");
            }
        }
        advanceOrder += " in advance";
        return advanceOrder;
    };
    Utils.cartItemsCheck = function (partner, cart, orderType) {
        var orderDays = Object.keys(getOrderDays(orderType, partner.pickupDays, partner.deliveryDays));
        var cartDates = Object.keys(cart);
        //Ensure the dates in the cart match the avaialblity depending on the order type
        for (var _i = 0, cartDates_1 = cartDates; _i < cartDates_1.length; _i++) {
            var date = cartDates_1[_i];
            if (!orderDays.includes(moment(date).day().toString())) {
                return false;
            }
        }
        return true;
    };
    Utils.OrderStatusText = function (status) {
        switch (status) {
            case OrderStatus.Unconfirmed:
                return 'Requires Action';
            case OrderStatus.Confirmed:
                return 'Preparing';
            case OrderStatus.Ready:
                return 'Ready';
            case OrderStatus.Completed:
                return 'Completed';
            case OrderStatus.Cancelled:
                return 'Cancelled';
            default:
                return 'Unknown';
        }
    };
    Utils.OrderCustomerStatusText = function (status, orderType, isOrderForToday) {
        if (status === OrderStatus.Cancelled) {
            return "Cancelled";
        }
        else if (status === OrderStatus.Unconfirmed) {
            return "Awaiting confirmation";
        }
        else if (status === OrderStatus.Confirmed) {
            return isOrderForToday ? "Preparing" : "Confirmed";
        }
        else if (status === OrderStatus.Ready) {
            if (orderType === OrderType.Pickup) {
                return "Ready for pickup";
            }
            else if (orderType === OrderType.Delivery) {
                return "Ready for delivery";
            }
        }
        else if (status === OrderStatus.Completed) {
            return "Completed";
        }
    };
    Utils.OrderStatusColour = function (status) {
        switch (status) {
            case OrderStatus.Unconfirmed:
                return Constants.UNCONFIRMED_COLOR;
            case OrderStatus.Confirmed:
                return Constants.CONFIRMED_COLOR;
            case OrderStatus.Ready:
                return Constants.READY_COLOR;
            case OrderStatus.Completed:
                return Constants.COMPLETE_COLOR;
            case OrderStatus.Cancelled:
                return Constants.CANCEL_COLOR;
        }
    };
    Utils.OrderStatusColourRGB = function (status) {
        switch (status) {
            case OrderStatus.Unconfirmed:
                return Constants.UNCONFIRMED_COLOR_RGB;
            case OrderStatus.Confirmed:
                return Constants.CONFIRMED_COLOR_RGB;
            case OrderStatus.Ready:
                return Constants.READY_COLOR_RGB;
            case OrderStatus.Completed:
                return Constants.COMPLETE_COLOR_RGB;
            case OrderStatus.Cancelled:
                return Constants.CANCEL_COLOR_RGB;
        }
    };
    Utils.createGoogleMapsURL = function (address) {
        return "https://maps.google.com/?daddr=".concat(encodeURIComponent(address), "&travelmode=driving");
    };
    Utils.calcAdjustedLatitude = function (lat, diff) {
        var newLat = lat - diff;
        if (newLat < -90) {
            return (newLat + 180);
        }
        else if (newLat > 90) {
            return (newLat - 180);
        }
        else {
            return newLat;
        }
    };
    Utils.calcAdjustedLongitude = function (lng, diff) {
        var newLng = lng - diff;
        if (newLng < -180) {
            return (newLng + 360);
        }
        else if (newLng > 180) {
            return (newLng - 360);
        }
        else {
            return newLng;
        }
    };
    Utils.convertToLowerName = function (name) { return name.toLowerCase().replaceAll(' ', '').replaceAll(/\'|’/g, ''); };
    Utils.getStoreStatus = function (orderType, orderDays, preparationEnabled, preparationDurationType, preparationDuration, adjustedDates, pauseDates, preparationInMins) {
        var _a, _b;
        var orderDay = Utils.getNextOrderDay(orderType, orderDays, preparationEnabled, preparationDurationType, preparationDuration, adjustedDates, pauseDates);
        var adjustedDate = orderType === OrderType.Pickup || orderType === OrderType.DineIn ?
            (_a = adjustedDates === null || adjustedDates === void 0 ? void 0 : adjustedDates[orderDay.format(DateFormat)]) === null || _a === void 0 ? void 0 : _a.pickupTimings :
            (_b = adjustedDates === null || adjustedDates === void 0 ? void 0 : adjustedDates[orderDay.format(DateFormat)]) === null || _b === void 0 ? void 0 : _b.deliveryTimings;
        var cutOffDate = cutoffDate(orderDay, orderDays[orderDay.day()], preparationEnabled, preparationDurationType, preparationDuration, adjustedDate, preparationInMins);
        //Calculate open time
        var openTime = moment(orderDay).startOf('day');
        if (adjustedDate) {
            openTime.add(adjustedDate.start, 'minutes');
        }
        else {
            openTime.add(orderDays[orderDay.day()].start, 'minutes');
        }
        if (orderType === OrderType.Pickup || orderType === OrderType.Delivery) {
            var isPausedToday = pauseDates === null || pauseDates === void 0 ? void 0 : pauseDates.includes(moment().startOf("day").format(DateFormat));
            if (isPausedToday) {
                return {
                    status: StoreOpenStatus.PausedToday
                };
            }
            else if (cutOffDate !== undefined && moment() > cutOffDate) {
                return {
                    status: StoreOpenStatus.NotAcceptingOrders
                };
            }
            else if (orderDay) {
                return {
                    status: StoreOpenStatus.Open,
                    cutOffTime: cutOffDate
                };
            }
        }
        else if (orderType === OrderType.DineIn) {
            if (!orderDay.isSame(moment(), 'day') || moment() > cutOffDate) {
                return {
                    status: StoreOpenStatus.NotAcceptingOrders
                };
            }
            else if (moment() < openTime) {
                return {
                    status: StoreOpenStatus.Closed
                };
            }
            else {
                return {
                    status: StoreOpenStatus.Open,
                    cutOffTime: cutOffDate
                };
            }
        }
    };
    return Utils;
}());
