import { useEffect, useRef, useState } from "react"
import { partnerReducer } from "@tiffin/app-common"

//Redux
import { useAppDispatch, useAppSelector } from "../hooks"

//3rd party components
import UsergroupAddOutlined from "@ant-design/icons/UsergroupAddOutlined"
import { Modal } from "antd"
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonToolbar, useIonRouter } from "@ionic/react"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { arrowBack } from "ionicons/icons"
import { Redirect } from "react-router"

//Forms
import { DetailsForm } from "./PartnerDetailsForm"
import { AdvanceForm } from "./PartnerAdvanceForm"
import { PickupForm } from "./PartnerPickupForm"
import { DeliveryForm } from "./PartnerDeliveryForm"
import { MenuForm } from "./PartnerMenuForm"
import { PaymentForm } from "./PartnerPaymentForm"
import { SubmitForm } from "./PartnerSubmitForm"
import { DineInForm } from "./PartnerDineInForm"
import { PlanForm } from "./PartnerPlanForm"
import { SubscriptionPlan } from "@tiffin/core"

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

import "./PartnerOnboarding.scss"
import "./PartnerForms.scss"

export function PartnerOnboarding(props: any) {
  SwiperCore.use([Pagination, Navigation]);

  const router = useIonRouter()

  //Redux
  const partnerStatus = useAppSelector((state) => state.partner.partnerStatus)
  const partnerIsRestaurant = useAppSelector((state) => state.partner.details?.isRestaurant)
  const partnerOfferDineIn = useAppSelector((state) => state.partner.pickup?.offerDineIn)
  const subscriptionPlan = useAppSelector((state) => state.partner.subscriptionPlan)

  const parnterOfferDineInRef = useRef<boolean | undefined>(partnerOfferDineIn);
  const status = useAppSelector((state) => state.partner.status)
  const dispatch = useAppDispatch()

  //Internal states
  const [discardModalVisible, setDiscardModalVisible] = useState<boolean>(false)
  const [swiper, setSwiper] = useState<SwiperCore>()

  //Populate the partner store
  useEffect(() => {
    if(status === "unknown" || status === "unregistered") {
      dispatch(partnerReducer.createPartnerSkeleton())
    }
  }, [dispatch, status])

  //Update the ref, we use a ref as we don't want the below effect to trigger if user changes the dinein
  useEffect(() => {
    parnterOfferDineInRef.current = partnerOfferDineIn
  }, [partnerOfferDineIn])

  //Move to the correct active slide
  const {step} = props.match.params;
  useEffect(() => {
    if(swiper && step === "payment") {
      swiper.slideTo(parnterOfferDineInRef.current ? 7 : 6)
    } else if(swiper && step === "subscribe") {
      swiper.slideTo(parnterOfferDineInRef.current ? 6 : 5)
    }
  }, [step, swiper])

  function renderDiscardConfirmation() {
    return (
      <Modal visible={discardModalVisible} onOk={handleBack} onCancel={() => setDiscardModalVisible(false)} okText="Continue">
        Any unsaved changes will be discarded
      </Modal>
    )
  }

  function handleBack() {
    setDiscardModalVisible(false)
    router.push("/main")
  }

  function doneCallback(action: "save" | "prev" | "next") {
    if(action === "prev") {
      swiper?.slidePrev()
    } else if(action === "next") {
      swiper?.slideNext()
    }
  }

  function swiperNavigate(index: number) {
    swiper?.slideTo(index)
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => setDiscardModalVisible(true)}>
              <IonIcon icon={arrowBack}></IonIcon>
            </IonButton>
          </IonButtons>
          <div className="page-header">
            <>
              <UsergroupAddOutlined />
              <span className="page-header-text">Partner Sign Up</span>
            </>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <Swiper 
          observer={true}
          observeParents={true}
          allowTouchMove = {false}
          preventClicks={false}
          onSwiper={(swiper) => setSwiper(swiper)} 
          pagination={{
            "type": "progressbar"
          }}  
          className="onboarding-swiper"
        >
          {swiper &&
          <>
            <SwiperSlide>
              <DetailsForm active={swiper.activeIndex === 0} onDone={doneCallback}/>
            </SwiperSlide>
            <SwiperSlide>
              <AdvanceForm active={swiper.activeIndex === 1} onDone={doneCallback}/>  
            </SwiperSlide>
            <SwiperSlide>
              <PickupForm active={swiper.activeIndex === 2} onDone={doneCallback} navigate={swiperNavigate}/>
            </SwiperSlide>
            {
              (partnerIsRestaurant && partnerOfferDineIn) &&
              <SwiperSlide>
                <DineInForm active={swiper.activeIndex === 3} onDone={doneCallback}/>  
              </SwiperSlide>
            }
            <SwiperSlide>
              <DeliveryForm active={(partnerIsRestaurant && partnerOfferDineIn) ? swiper.activeIndex === 4 : swiper.activeIndex === 3} onDone={doneCallback}/>  
            </SwiperSlide>
            <SwiperSlide>
              <MenuForm active={(partnerIsRestaurant && partnerOfferDineIn) ? swiper.activeIndex === 5 : swiper.activeIndex === 4} onDone={doneCallback}/>  
            </SwiperSlide>
            <SwiperSlide>
              <PlanForm onDone={doneCallback}/>  
            </SwiperSlide>
            {
              (subscriptionPlan === SubscriptionPlan.OnlineOrderingMonthly || subscriptionPlan === SubscriptionPlan.OnlineOrderingAnnual) &&
              <SwiperSlide>
                <PaymentForm onDone={doneCallback}/>  
              </SwiperSlide>
            }
            <SwiperSlide>
              <SubmitForm onDone={doneCallback}/>  
            </SwiperSlide>
          </>
          }
        </Swiper>
        {renderDiscardConfirmation()}
        {
          (partnerStatus === "pending") &&
          <Redirect to="/main/status" />
        }
      </IonContent>
    </IonPage>
  )
}
