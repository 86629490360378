import styled from "styled-components";

//3rd party
import { Button } from "antd"
import { IonIcon } from "@ionic/react";
import { checkmarkOutline } from "ionicons/icons";
import { NEW_CUSTOMER_DISCOUNT, SubscriptionPlan } from "@tiffin/core";
import { useAppSelector } from "../hooks";

export const PlanCard = styled(BasePlanCard)`
  border: 1px solid var(--ion-color-light);
  border-radius: 8px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 350px;

  &.selected {
    border: 2px solid var(--ion-color-primary);
  }

  @media (max-width:768px)  {
    width: 100%;
  }

  .title {
    font-size: large;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
  }

  .sub-title {
    font-size: small;
  }

  .discount-percentage {
    font-size: smaller;
    padding: 5px;
    background: var(--ion-color-primary);
    color: #fff;
    border-radius: 8px;
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .price-details {
    font-weight: 700;
    display: flex;
    gap: 5px;
    align-items: flex-end;

    .price {
      font-size: xx-large;

      &.discounted {
        text-decoration: line-through;
      }
    }

    .frequency {
      font-size: smaller;
      padding-bottom: 4px;
      color: var(--ion-color-medium-shade);
    }
  }

  .discount-info {
    font-size: small;
  }

  ul {
    list-style: none;
    padding: 0;
    display: grid;
    gap: 10px;

    li {
      font-size: small;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .free-trial {
    font-weight: 700;
    color: var(--ion-color-dark-tint);
  }
`

interface IPlanCardForm {
  className?: string,
  title: string,
  subTitle?: string,
  price: number,
  frequency: "monthly" | "annually",
  features: string[],
  onChange: (newPlan: SubscriptionPlan) => void,
  plan: SubscriptionPlan,
  loadingPlan?: SubscriptionPlan
}

export function BasePlanCard({className, title, subTitle, price, frequency, features, onChange, plan, loadingPlan}: IPlanCardForm) {

  const subscriptionPlan = useAppSelector((state) => state.partner.subscriptionPlan)
  const subscriptionTrialClaimed = useAppSelector((state) => state.partner.subscriptionTrialClaimed)

  return (
    <div className={`${className} ${subscriptionPlan === plan ? 'selected' : ''}`}>
      <div className="title">
        {title}
        {
          !subscriptionTrialClaimed &&
          <div className="discount-percentage">50% off</div>
        }
      </div>
      <div className="sub-title">{subTitle}</div>
      <div className="price-details">
        {
          (subscriptionTrialClaimed || price === 0) ?
          <span className="price">
            {price > 0 ? `$${(price / 100)}` : 'Free'}
          </span>
          :
          <>
            <span className="price discounted">
              {price > 0 ? `$${(price / 100)}` : 'Free'}
            </span>
            <span className="price">
              {price > 0 ? `$${(price * NEW_CUSTOMER_DISCOUNT / 100).toFixed(2)}` : 'Free'}
            </span>
          </>
        }
        {
          price > 0 &&
          <span className="frequency">
            {frequency === "monthly" ? "per month" : "per year"}
          </span>
        }
      </div>
      {
        !subscriptionTrialClaimed &&
        <span className="discount-info">First 12 months, ${(price / 100)} {frequency === "monthly" ? "per month" : "per year"} thereafter</span>
      }
      {
        !subscriptionTrialClaimed &&
        <div className="free-trial">
          30 day free trial
        </div>
      }
      <Button disabled={!!loadingPlan} loading={loadingPlan === plan} onClick={() => onChange(plan)} type={subscriptionPlan === plan ? 'primary' : 'default'}>
        {
          subscriptionPlan === plan ? 'Manage' : 'Buy plan'
        }
      </Button>
      <ul>
        {
          features.map((feature) => {
            return (
              <li>
                <IonIcon icon={checkmarkOutline} style={{color: "var(--ion-color-primary)", fontSize: "1.2em"}} />
                {feature}
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}
