"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderStatus = exports.COMMISSON_FEE = exports.WEBSITE_PROCESSING_FEE = void 0;
exports.WEBSITE_PROCESSING_FEE = 0.02; // This is the fee we charge partner services
exports.COMMISSON_FEE = 0.075; // This is the fee we charge partner services
var OrderStatus;
(function (OrderStatus) {
    OrderStatus[OrderStatus["Unpaid"] = 0] = "Unpaid";
    OrderStatus[OrderStatus["PendingPayment"] = 1] = "PendingPayment";
    OrderStatus[OrderStatus["Unconfirmed"] = 2] = "Unconfirmed";
    OrderStatus[OrderStatus["Confirmed"] = 3] = "Confirmed";
    OrderStatus[OrderStatus["Ready"] = 4] = "Ready";
    OrderStatus[OrderStatus["Completed"] = 5] = "Completed";
    OrderStatus[OrderStatus["Cancelled"] = 6] = "Cancelled";
})(OrderStatus = exports.OrderStatus || (exports.OrderStatus = {}));
