var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";
import { detailsFields, advanceFields, pickupFields, deliveryFields, dineInFields, StripeOnboarding, OutOfStockType, DateFormat, ARCHVIED_CATEGORY, AvailabilityType, websiteDetailsFields } from "@tiffin/core";
import { createPartner, createTiffin, fetchCustomerReviews, fetchNextCustomerReviews, getPartnerInfo, getReview, removeMenuItemImage, removePartnerImage, removePartnerLogo, updateMenuItemImage, updatePartner, updatePartnerAdjustedDates, updatePartnerImage, updatePartnerLogo, updatePartnerPauseDates, updatePartnerWebsiteDetails, updateTiffin, } from "../firebase-helpers/partner";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";
import { cloudFunction } from "../firebase";
import moment from "moment";
var initialState = {
    id: "",
    status: "unknown",
    menuStatus: "unknown",
    paymentStatus: "unknown",
    reviewsStatus: "unknown",
    imageUrl: null,
    pendingImageUrl: null,
    logoImageUrl: null,
    customerReviews: [],
    hasMoreCustomerReviews: true,
    adjustedDates: {},
    pauseDates: [],
    menu: {
        Entrees: { order: 0, items: [] },
        Mains: { order: 1, items: [] },
        Desserts: { order: 2, items: [] },
    },
    modifiers: [],
    archived: [],
    unsavedMenuChanges: false
};
export var createPartnerSkeleton = createAsyncThunk("partner/createPartnerSkeleton", function () { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, createPartner()];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); });
export var getPartner = createAsyncThunk("partner/getPartner", function () { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getPartnerInfo()];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); });
export var getPaymentStatus = createAsyncThunk("partner/paymentStatus", function () { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, cloudFunction("checkPaymentSetup")];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); });
export var updateDetails = createAsyncThunk("partner/updateDetails", function (details) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!!isEqual(store.getState().partner.details, details)) return [3 /*break*/, 2];
                return [4 /*yield*/, updatePartner(details)];
            case 1:
                _a.sent();
                return [2 /*return*/, details];
            case 2: return [2 /*return*/];
        }
    });
}); });
export var updateAdvance = createAsyncThunk("partner/updateAdvance", function (advance) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!!isEqual(store.getState().partner.advance, advance)) return [3 /*break*/, 2];
                return [4 /*yield*/, updatePartner(advance)];
            case 1:
                _a.sent();
                return [2 /*return*/, advance];
            case 2: return [2 /*return*/];
        }
    });
}); });
export var updatePickup = createAsyncThunk("partner/updatePickup", function (pickup, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, updatedMenu, daysRemoved, day;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    state = getState();
                    if (!!isEqual(state.partner.pickup, pickup)) return [3 /*break*/, 4];
                    daysRemoved = [];
                    for (day in state.partner.pickup.pickupDays) {
                        if (!pickup.pickupDays[day]) {
                            // A day was removed, check if it is a delivery day
                            if (!((_c = (_b = state.partner.delivery) === null || _b === void 0 ? void 0 : _b.deliveryDays) === null || _c === void 0 ? void 0 : _c[day])) {
                                daysRemoved.push(Number(day));
                            }
                        }
                    }
                    if (!(daysRemoved.length > 0)) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateMenuAvailability(state.partner, daysRemoved)];
                case 1:
                    //Update menu to remove availability from any items where a day is now unavailable
                    updatedMenu = _d.sent();
                    _d.label = 2;
                case 2: return [4 /*yield*/, updatePartner(pickup)];
                case 3:
                    _d.sent();
                    return [2 /*return*/, { pickup: pickup, menu: updatedMenu }];
                case 4: return [2 /*return*/];
            }
        });
    });
});
export var updateDelivery = createAsyncThunk("partner/updateDelivery", function (delivery, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, updatedMenu, daysRemoved, day;
        var _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    state = getState();
                    if (!!isEqual(store.getState().partner.delivery, delivery)) return [3 /*break*/, 4];
                    daysRemoved = [];
                    for (day in state.partner.delivery.deliveryDays) {
                        if (!((_b = delivery.deliveryDays) === null || _b === void 0 ? void 0 : _b[day])) {
                            // A day was removed, check if it is a pickup day
                            if (!((_d = (_c = state.partner.pickup) === null || _c === void 0 ? void 0 : _c.pickupDays) === null || _d === void 0 ? void 0 : _d[day])) {
                                daysRemoved.push(Number(day));
                            }
                        }
                    }
                    if (!(daysRemoved.length > 0)) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateMenuAvailability(state.partner, daysRemoved)];
                case 1:
                    //Update menu to remove availability from any items where a day is now unavailable
                    updatedMenu = _e.sent();
                    _e.label = 2;
                case 2: return [4 /*yield*/, updatePartner(delivery)];
                case 3:
                    _e.sent();
                    return [2 /*return*/, { delivery: delivery, menu: updatedMenu }];
                case 4: return [2 /*return*/];
            }
        });
    });
});
export var updateDineIn = createAsyncThunk("partner/updateDineIn", function (details, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state;
        var _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    state = getState();
                    if (!(details.absorbPaymentFee !== ((_b = state.partner.dineIn) === null || _b === void 0 ? void 0 : _b.absorbPaymentFee) ||
                        details.numberOfTables !== ((_c = state.partner.dineIn) === null || _c === void 0 ? void 0 : _c.numberOfTables) ||
                        details.autoConfirmDineInOrders !== ((_d = state.partner.dineIn) === null || _d === void 0 ? void 0 : _d.autoConfirmDineInOrders))) return [3 /*break*/, 2];
                    return [4 /*yield*/, updatePartner(details)];
                case 1:
                    _e.sent();
                    return [2 /*return*/, details];
                case 2: return [2 /*return*/];
            }
        });
    });
});
export var updatePrepTime = createAsyncThunk("partner/updatePrepTime", function (prepTimeInMins, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    state = getState();
                    if (!(prepTimeInMins !== ((_b = state.partner.advance) === null || _b === void 0 ? void 0 : _b.preparationInMins))) return [3 /*break*/, 2];
                    return [4 /*yield*/, updatePartner(__assign(__assign({}, state.partner.advance), { preparationInMins: prepTimeInMins }))];
                case 1:
                    _c.sent();
                    return [2 /*return*/, prepTimeInMins];
                case 2: return [2 /*return*/];
            }
        });
    });
});
export var updateWebsiteDetails = createAsyncThunk("partner/updateWebsiteOptions", function (websiteDetails, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    if (!(websiteDetails.urlName !== state.partner.urlName ||
                        websiteDetails.facebookUrl !== state.partner.facebookUrl ||
                        websiteDetails.instagramUrl !== state.partner.instagramUrl ||
                        websiteDetails.tiktokUrl !== state.partner.tiktokUrl)) return [3 /*break*/, 2];
                    return [4 /*yield*/, updatePartnerWebsiteDetails(websiteDetails)];
                case 1:
                    _b.sent();
                    return [2 /*return*/, websiteDetails];
                case 2: return [2 /*return*/];
            }
        });
    });
});
export var updateAdjustedDates = createAsyncThunk("partner/updateAdjustedDates", function (adjustedDates, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    if (!!isEqual(state.partner.adjustedDates, adjustedDates)) return [3 /*break*/, 2];
                    return [4 /*yield*/, updatePartnerAdjustedDates(adjustedDates)];
                case 1:
                    _b.sent();
                    return [2 /*return*/, adjustedDates];
                case 2: return [2 /*return*/];
            }
        });
    });
});
export var updatePauseDates = createAsyncThunk("partner/updatePauseDates", function (dates, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    if (!!isEqual(state.partner.pauseDates, dates)) return [3 /*break*/, 2];
                    return [4 /*yield*/, updatePartnerPauseDates(dates)];
                case 1:
                    _b.sent();
                    return [2 /*return*/, dates];
                case 2: return [2 /*return*/];
            }
        });
    });
});
export var createMenuSkeleton = createAsyncThunk("partner/createMenuSkeleton", function (_a, _b) {
    var getState = _b.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, response;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    state = getState();
                    return [4 /*yield*/, createTiffin(state.partner.menu)];
                case 1:
                    response = _c.sent();
                    return [2 /*return*/, response];
            }
        });
    });
});
export var getMenu = createAsyncThunk("partner/getMenu", function (_a, _b) {
    var getState = _b.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, response, archived;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    state = getState();
                    return [4 /*yield*/, createTiffin(state.partner.menu)];
                case 1:
                    response = _c.sent();
                    archived = [];
                    if (response.menu[ARCHVIED_CATEGORY]) {
                        archived = cloneDeep(response.menu[ARCHVIED_CATEGORY].items);
                        delete response.menu[ARCHVIED_CATEGORY];
                    }
                    return [2 /*return*/, {
                            menuDetails: response,
                            archived: archived
                        }];
            }
        });
    });
});
export var saveMenu = createAsyncThunk("partner/saveMenu", function (_a, _b) {
    var getState = _b.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, menu, modifiers;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    state = getState();
                    menu = cloneDeep(state.partner.menu);
                    modifiers = cloneDeep(state.partner.modifiers);
                    menu[ARCHVIED_CATEGORY] = {
                        items: cloneDeep(state.partner.archived),
                        order: -1
                    };
                    return [4 /*yield*/, updateTiffin(menu, modifiers)];
                case 1:
                    _c.sent();
                    return [2 /*return*/];
            }
        });
    });
});
export var updateMenuImage = createAsyncThunk("partner/updateMenuImage", function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var url;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!payload.image) return [3 /*break*/, 2];
                return [4 /*yield*/, updateMenuItemImage(payload.image, payload.menuItemId)];
            case 1:
                url = _a.sent();
                return [2 /*return*/, url];
            case 2:
                if (!(payload.image === null)) return [3 /*break*/, 4];
                return [4 /*yield*/, removeMenuItemImage(payload.menuItemId)];
            case 3:
                _a.sent();
                return [2 /*return*/, null];
            case 4: return [2 /*return*/];
        }
    });
}); });
export var updateCoverImage = createAsyncThunk("partner/updateCoverImage", function (image) { return __awaiter(void 0, void 0, void 0, function () {
    var url;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!image) return [3 /*break*/, 2];
                return [4 /*yield*/, updatePartnerImage(image)];
            case 1:
                url = _a.sent();
                return [2 /*return*/, url];
            case 2:
                if (!(image === null)) return [3 /*break*/, 4];
                return [4 /*yield*/, removePartnerImage()];
            case 3:
                _a.sent();
                return [2 /*return*/, null];
            case 4: return [2 /*return*/];
        }
    });
}); });
export var updateLogoImage = createAsyncThunk("partner/updateLogoImage", function (image) { return __awaiter(void 0, void 0, void 0, function () {
    var url;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!image) return [3 /*break*/, 2];
                return [4 /*yield*/, updatePartnerLogo(image)];
            case 1:
                url = _a.sent();
                return [2 /*return*/, url];
            case 2:
                if (!(image === null)) return [3 /*break*/, 4];
                return [4 /*yield*/, removePartnerLogo()];
            case 3:
                _a.sent();
                return [2 /*return*/, null];
            case 4: return [2 /*return*/];
        }
    });
}); });
export var getReviews = createAsyncThunk("partner/getReviews", function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchCustomerReviews()];
            case 1: return [2 /*return*/, (_a.sent())];
        }
    });
}); });
export var getNextReviews = createAsyncThunk("partner/getNextReviews", function (_a, _b) {
    var getState = _b.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, lastReview;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    state = getState();
                    if (!(state.partner.customerReviews.length > 1)) return [3 /*break*/, 3];
                    return [4 /*yield*/, getReview(state.partner.customerReviews[state.partner.customerReviews.length - 1].id)];
                case 1:
                    lastReview = _c.sent();
                    return [4 /*yield*/, fetchNextCustomerReviews(lastReview)];
                case 2: return [2 /*return*/, (_c.sent())];
                case 3: return [2 /*return*/];
            }
        });
    });
});
function updateMenuAvailability(partner, daysRemoved) {
    var _this = this;
    //Update menu to remove availability from any items where a day is now unavailable
    var menu = cloneDeep(partner.menu);
    menu[ARCHVIED_CATEGORY] = {
        items: partner.archived,
        order: -1
    };
    for (var category in menu) {
        for (var _i = 0, _a = menu[category].items; _i < _a.length; _i++) {
            var item = _a[_i];
            if (item.availabilityType === AvailabilityType.Custom) {
                item.availability = item.availability.filter(function (n) { return !daysRemoved.includes(n); });
            }
        }
    }
    return new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateTiffin(menu, partner.modifiers)];
                case 1:
                    _a.sent();
                    resolve(menu);
                    return [2 /*return*/];
            }
        });
    }); });
}
export var partnerSlice = createSlice({
    name: "partner",
    initialState: initialState,
    reducers: {
        resetStatus: function (state) {
            state.status = "idle";
        },
        resetMenuStatus: function (state) {
            state.menuStatus = "idle";
        },
        resetError: function (state) {
            state.error = "";
        },
        setSubscriptionPlan: function (state, action) {
            state.subscriptionPlan = action.payload.plan;
        },
        setPartnerStatus: function (state, action) {
            state.partnerStatus = action.payload;
        },
        addCategory: function (state, action) {
            if (state.menu[action.payload.name]) {
                //Category already exists
                state.error = "Category already exists";
            }
            else {
                state.menu[action.payload.name] = {
                    order: Object.keys(state.menu).length,
                    items: []
                };
                state.unsavedMenuChanges = true;
            }
        },
        updateCategory: function (state, action) {
            if (action.payload.oldName !== action.payload.newName) {
                state.menu[action.payload.newName] = cloneDeep(state.menu[action.payload.oldName]);
                delete state.menu[action.payload.oldName];
                state.unsavedMenuChanges = true;
            }
        },
        deleteCategory: function (state, action) {
            for (var category in state.menu) {
                if (state.menu[category].order > state.menu[action.payload.name].order) {
                    state.menu[category].order--;
                }
            }
            delete state.menu[action.payload.name];
            state.unsavedMenuChanges = true;
        },
        reorderCategory: function (state, action) {
            for (var category in state.menu) {
                if (action.payload.from < action.payload.to) {
                    if (state.menu[category].order === action.payload.from) {
                        state.menu[category].order = action.payload.to;
                    }
                    else if (state.menu[category].order > action.payload.from && state.menu[category].order <= action.payload.to) {
                        state.menu[category].order = state.menu[category].order - 1;
                    }
                }
                if (action.payload.from > action.payload.to) {
                    if (state.menu[category].order === action.payload.from) {
                        state.menu[category].order = action.payload.to;
                    }
                    else if (state.menu[category].order >= action.payload.to && state.menu[category].order < action.payload.from) {
                        state.menu[category].order = state.menu[category].order + 1;
                    }
                }
            }
            state.unsavedMenuChanges = true;
        },
        reorderItem: function (state, action) {
            if (state.menu[action.payload.category]) {
                var items = state.menu[action.payload.category].items;
                // Delete the item from it's current position
                var item = items.splice(action.payload.from, 1);
                // Make sure there's an item to move
                if (!item.length) {
                    throw new Error("There is no item in the array at index ".concat(action.payload.from));
                }
                // Move the item to its new position
                items.splice(action.payload.to, 0, item[0]);
                state.unsavedMenuChanges = true;
            }
            else {
                throw new Error("There is no category ".concat(action.payload.category));
            }
        },
        addItem: function (state, action) {
            if (state.menu[action.payload.category]) {
                state.menu[action.payload.category].items.push(__assign({}, action.payload.item));
                state.unsavedMenuChanges = true;
            }
            else {
                throw new Error("There is no category ".concat(action.payload.category));
            }
        },
        deleteItem: function (state, action) {
            if (state.menu[action.payload.category]) {
                var item = state.menu[action.payload.category].items.splice(state.menu[action.payload.category].items.findIndex(function (item) { return item.id === action.payload.itemId; }), 1);
                if (!item[0]) {
                    throw new Error("There is no item of id=".concat(action.payload.itemId, ", in category=").concat(action.payload.category));
                }
                state.unsavedMenuChanges = true;
            }
            else {
                throw new Error("There is no category ".concat(action.payload.category));
            }
        },
        deleteArchiveItem: function (state, action) {
            var item = state.archived.splice(state.archived.findIndex(function (item) { return item.id === action.payload.itemId; }), 1);
            if (!item[0]) {
                throw new Error("There is no item of id=".concat(action.payload.itemId, ", in archived items"));
            }
            state.unsavedMenuChanges = true;
        },
        archiveItem: function (state, action) {
            if (state.menu[action.payload.category]) {
                var item = state.menu[action.payload.category].items.splice(state.menu[action.payload.category].items.findIndex(function (item) { return item.id === action.payload.itemId; }), 1);
                if (item[0]) {
                    state.archived.push(item[0]);
                    state.unsavedMenuChanges = true;
                }
                else {
                    throw new Error("There is no item of id=".concat(action.payload.itemId, ", in category=").concat(action.payload.category));
                }
            }
            else {
                throw new Error("There is no category ".concat(action.payload.category));
            }
        },
        unarchiveItem: function (state, action) {
            var item = state.archived.splice(state.archived.findIndex(function (item) { return item.id === action.payload.itemId; }), 1);
            if (!item[0]) {
                throw new Error("There is no item of id=".concat(action.payload.itemId, ", in archived items"));
            }
            if (state.menu[action.payload.category]) {
                state.menu[action.payload.category].items.push(item[0]);
                state.unsavedMenuChanges = true;
            }
            else {
                throw new Error("There is no category ".concat(action.payload.category));
            }
        },
        moveItem: function (state, action) {
            if (state.menu[action.payload.oldCategory]) {
                var item = state.menu[action.payload.oldCategory].items.splice(state.menu[action.payload.oldCategory].items.findIndex(function (item) { return item.id === action.payload.itemId; }), 1);
                if (item[0]) {
                    if (state.menu[action.payload.newCategory]) {
                        state.menu[action.payload.newCategory].items.push(item[0]);
                        state.unsavedMenuChanges = true;
                    }
                    else {
                        throw new Error("There is no category ".concat(action.payload.newCategory));
                    }
                }
                else {
                    throw new Error("There is no item of id=".concat(action.payload.itemId, ", in category=").concat(action.payload.oldCategory));
                }
            }
            else {
                throw new Error("There is no category ".concat(action.payload.oldCategory));
            }
        },
        updateItem: function (state, action) {
            if (state.menu[action.payload.category]) {
                for (var _i = 0, _a = state.menu[action.payload.category].items; _i < _a.length; _i++) {
                    var item = _a[_i];
                    var changed = false;
                    if (item.id === action.payload.item.id) {
                        if (item.name !== action.payload.item.name) {
                            item.name = action.payload.item.name;
                            changed = true;
                        }
                        if (item.description !== action.payload.item.description) {
                            item.description = action.payload.item.description;
                            changed = true;
                        }
                        if (item.price !== action.payload.item.price) {
                            item.price = action.payload.item.price;
                            changed = true;
                        }
                        if (item.outOfStockType !== action.payload.item.outOfStockType) {
                            item.outOfStockType = action.payload.item.outOfStockType;
                            if (item.outOfStockType === OutOfStockType.None) {
                                item.outOfStockDates = [];
                            }
                            changed = true;
                        }
                        if (item.outOfStockType !== OutOfStockType.None && !isEqual(item.outOfStockDates, action.payload.item.outOfStockDates)) {
                            item.outOfStockDates = action.payload.item.outOfStockDates;
                            changed = true;
                        }
                        if (!isEqual(item.availabilityType, action.payload.item.availabilityType)) {
                            item.availabilityType = action.payload.item.availabilityType;
                            changed = true;
                        }
                        if (!isEqual(item.availability, action.payload.item.availability)) {
                            if (action.payload.item.availability === undefined) {
                                delete item.availability;
                            }
                            else {
                                item.availability = action.payload.item.availability;
                            }
                            changed = true;
                        }
                        if (!isEqual(item.dietaryAttributes, action.payload.item.dietaryAttributes)) {
                            item.dietaryAttributes = action.payload.item.dietaryAttributes;
                            changed = true;
                        }
                        if (action.payload.item.imageText) {
                            item.imageText = action.payload.item.imageText;
                            changed = true;
                        }
                        if (!isEqual(item.modifiers, action.payload.item.modifiers)) {
                            item.modifiers = action.payload.item.modifiers;
                            changed = true;
                        }
                        if (action.payload.deleteImage) {
                            delete item.imageUrl;
                            changed = true;
                        }
                        state.unsavedMenuChanges = state.unsavedMenuChanges || changed;
                        break;
                    }
                }
            }
            else {
                throw new Error('There is no category ' + action.payload.category);
            }
        },
        updateArchiveItem: function (state, action) {
            for (var _i = 0, _a = state.archived; _i < _a.length; _i++) {
                var item = _a[_i];
                var changed = false;
                if (item.id === action.payload.item.id) {
                    if (item.name !== action.payload.item.name) {
                        item.name = action.payload.item.name;
                        changed = true;
                    }
                    if (item.description !== action.payload.item.description) {
                        item.description = action.payload.item.description;
                        changed = true;
                    }
                    if (item.price !== action.payload.item.price) {
                        item.price = action.payload.item.price;
                        changed = true;
                    }
                    if (item.outOfStockType !== action.payload.item.outOfStockType) {
                        item.outOfStockType = action.payload.item.outOfStockType;
                        if (item.outOfStockType === OutOfStockType.None) {
                            item.outOfStockDates = [];
                        }
                        changed = true;
                    }
                    if (item.outOfStockType !== OutOfStockType.None && !isEqual(item.outOfStockDates, action.payload.item.outOfStockDates)) {
                        item.outOfStockDates = action.payload.item.outOfStockDates;
                        changed = true;
                    }
                    if (!isEqual(item.availabilityType, action.payload.item.availabilityType)) {
                        item.availabilityType = action.payload.item.availabilityType;
                        changed = true;
                    }
                    if (!isEqual(item.availability, action.payload.item.availability)) {
                        if (action.payload.item.availability === undefined) {
                            delete item.availability;
                        }
                        else {
                            item.availability = action.payload.item.availability;
                        }
                        changed = true;
                    }
                    if (!isEqual(item.dietaryAttributes, action.payload.item.dietaryAttributes)) {
                        item.dietaryAttributes = action.payload.item.dietaryAttributes;
                        changed = true;
                    }
                    if (action.payload.item.imageText) {
                        item.imageText = action.payload.item.imageText;
                        changed = true;
                    }
                    if (!isEqual(item.modifiers, action.payload.item.modifiers)) {
                        item.modifiers = action.payload.item.modifiers;
                        changed = true;
                    }
                    if (action.payload.deleteImage) {
                        delete item.imageUrl;
                        changed = true;
                    }
                    state.unsavedMenuChanges = state.unsavedMenuChanges || changed;
                    break;
                }
            }
        },
        addModifier: function (state, action) {
            state.modifiers.push(action.payload.modifier);
            state.unsavedMenuChanges = true;
        },
        updateModifier: function (state, action) {
            var modifier = state.modifiers.find(function (val) { return val.id === action.payload.modifier.id; });
            if (!isEqual(modifier, action.payload.modifier)) {
                modifier.name = action.payload.modifier.name;
                modifier.options = action.payload.modifier.options;
                modifier.required = action.payload.modifier.required;
                modifier.type = action.payload.modifier.type;
                state.unsavedMenuChanges = true;
            }
        },
        removeModifier: function (state, action) {
            state.modifiers = state.modifiers.filter(function (val) { return val.id !== action.payload.id; });
            state.unsavedMenuChanges = true;
        }
    },
    extraReducers: function (builder) {
        builder.addCase(createPartnerSkeleton.pending, function (state) {
            state.status = "loading";
        });
        builder.addCase(createPartnerSkeleton.fulfilled, function (state, action) {
            state.status = "idle";
            populate(state, action);
        });
        builder.addCase(createPartnerSkeleton.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        builder.addCase(getPartner.pending, function (state) {
            state.status = "loading";
        });
        builder.addCase(getPartner.fulfilled, function (state, action) {
            state.status = "idle";
            populate(state, action);
        });
        builder.addCase(getPartner.rejected, function (state, action) {
            state.status = "unregistered";
            state.error = action.error.message;
        });
        //details
        builder.addCase(updateDetails.pending, function (state) {
            state.status = "loading";
        });
        builder.addCase(updateDetails.fulfilled, function (state, action) {
            state.status = "saved";
            if (action.payload) {
                var details_1 = {};
                detailsFields.forEach(function (fieldName, index) {
                    if (action.payload[fieldName] !== undefined) {
                        if (!details_1)
                            details_1 = {};
                        details_1[fieldName] = action.payload[fieldName];
                    }
                });
                state.details = details_1;
                websiteDetailsFields.forEach(function (fieldName, index) {
                    if (action.payload[fieldName] !== undefined) {
                        if (!details_1)
                            details_1 = {};
                        state[fieldName] = action.payload[fieldName];
                    }
                });
            }
        });
        builder.addCase(updateDetails.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        //pause dates
        builder.addCase(updatePauseDates.fulfilled, function (state, action) {
            if (action.payload) {
                state.pauseDates = action.payload;
            }
        });
        builder.addCase(updatePauseDates.rejected, function (state, action) {
            state.error = action.error.message;
        });
        //adjusted dates
        builder.addCase(updateAdjustedDates.pending, function (state) {
            state.status = "loading";
        });
        builder.addCase(updateAdjustedDates.fulfilled, function (state, action) {
            state.status = "saved";
            if (action.payload) {
                state.adjustedDates = __assign({}, action.payload);
            }
        });
        builder.addCase(updateAdjustedDates.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        builder.addCase(updateCoverImage.fulfilled, function (state, action) {
            if (action.payload) {
                state.pendingImageUrl = action.payload;
            }
            else {
                state.pendingImageUrl = null;
            }
        });
        builder.addCase(updateCoverImage.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        builder.addCase(updateLogoImage.fulfilled, function (state, action) {
            if (action.payload) {
                state.logoImageUrl = action.payload;
            }
            else {
                state.logoImageUrl = null;
            }
        });
        builder.addCase(updateLogoImage.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        //advance
        builder.addCase(updateAdvance.pending, function (state) {
            state.status = "loading";
        });
        builder.addCase(updateAdvance.fulfilled, function (state, action) {
            state.status = "saved";
            if (action.payload) {
                state.advance = __assign({}, action.payload);
            }
        });
        builder.addCase(updateAdvance.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        builder.addCase(updatePrepTime.pending, function (state) {
            state.status = "loading";
        });
        builder.addCase(updatePrepTime.fulfilled, function (state, action) {
            state.status = "saved";
            if (action.payload !== undefined) {
                state.advance.preparationInMins = action.payload;
            }
        });
        builder.addCase(updatePrepTime.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        //pickup
        builder.addCase(updatePickup.pending, function (state) {
            state.status = "loading";
        });
        builder.addCase(updatePickup.fulfilled, function (state, action) {
            state.status = "saved";
            if (action.payload) {
                state.pickup = __assign({}, action.payload.pickup);
                if (!action.payload.pickup.offerDineIn) {
                    delete state.dineIn;
                }
                if (action.payload.menu)
                    state.menu = action.payload.menu;
            }
        });
        builder.addCase(updatePickup.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        //delivery
        builder.addCase(updateDelivery.pending, function (state) {
            state.status = "loading";
        });
        builder.addCase(updateDelivery.fulfilled, function (state, action) {
            state.status = "saved";
            if (action.payload) {
                state.delivery = __assign({}, action.payload.delivery);
                if (action.payload.menu)
                    state.menu = action.payload.menu;
            }
        });
        builder.addCase(updateDelivery.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        builder.addCase(updateDineIn.pending, function (state) {
            state.status = "loading";
        });
        builder.addCase(updateDineIn.fulfilled, function (state, action) {
            state.status = "saved";
            if (action.payload) {
                state.dineIn = __assign({}, action.payload);
            }
        });
        builder.addCase(updateDineIn.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        builder.addCase(updateWebsiteDetails.pending, function (state) {
            state.status = "loading";
        });
        builder.addCase(updateWebsiteDetails.fulfilled, function (state, action) {
            state.status = "saved";
            if (action.payload) {
                state.urlName = action.payload.urlName;
                state.facebookUrl = action.payload.facebookUrl;
                state.instagramUrl = action.payload.instagramUrl;
                state.tiktokUrl = action.payload.tiktokUrl;
            }
        });
        builder.addCase(updateWebsiteDetails.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        //menu create
        builder.addCase(createMenuSkeleton.pending, function (state) {
            state.menuStatus = "loading";
        });
        builder.addCase(createMenuSkeleton.fulfilled, function (state, action) {
            state.menuStatus = "idle";
            if (action.payload) {
                state.menu = __assign({}, action.payload.menu);
                state.modifiers = action.payload.modifiers;
            }
        });
        builder.addCase(createMenuSkeleton.rejected, function (state, action) {
            state.menuStatus = "failed";
            state.error = action.error.message;
        });
        //get menu
        builder.addCase(getMenu.pending, function (state) {
            state.menuStatus = "loading";
        });
        builder.addCase(getMenu.fulfilled, function (state, action) {
            var _a, _b;
            state.menuStatus = "idle";
            if (action.payload) {
                //Filter menu out of stock dates to only future dates
                for (var category in action.payload.menuDetails.menu) {
                    for (var _i = 0, _c = action.payload.menuDetails.menu[category].items; _i < _c.length; _i++) {
                        var item = _c[_i];
                        if (item.outOfStockType !== OutOfStockType.None) {
                            var dates = (_b = (_a = item.outOfStockDates) === null || _a === void 0 ? void 0 : _a.filter(function (date) { return moment().startOf('day') <= moment(date, DateFormat); })) !== null && _b !== void 0 ? _b : [];
                            item.outOfStockDates = dates;
                            //If there are no valid dates, reset the out of stock
                            if (dates.length === 0) {
                                item.outOfStockType = OutOfStockType.None;
                            }
                        }
                    }
                }
                state.menu = action.payload.menuDetails.menu;
                state.modifiers = action.payload.menuDetails.modifiers;
                state.archived = action.payload.archived;
            }
            state.unsavedMenuChanges = false;
        });
        builder.addCase(getMenu.rejected, function (state, action) {
            state.menuStatus = "failed";
            state.error = action.error.message;
        });
        //save menu
        builder.addCase(saveMenu.pending, function (state) {
            state.menuStatus = "saving";
        });
        builder.addCase(saveMenu.fulfilled, function (state, action) {
            state.menuStatus = "saved";
            state.unsavedMenuChanges = false;
        });
        builder.addCase(saveMenu.rejected, function (state, action) {
            state.menuStatus = "failed";
            state.error = action.error.message;
            console.error(action.error);
        });
        //payment status
        builder.addCase(getPaymentStatus.pending, function (state) {
            state.paymentStatus = "loading";
        });
        builder.addCase(getPaymentStatus.fulfilled, function (state, action) {
            if (action.payload.data && action.payload.data.onboarding === StripeOnboarding.Complete) {
                state.paymentStatus = "complete";
            }
            else {
                state.paymentStatus = "incomplete";
            }
        });
        builder.addCase(getPaymentStatus.rejected, function (state, action) {
            state.menuStatus = "failed";
            state.error = action.error.message;
            console.error(action.error);
        });
        //Get customer reviews
        builder.addCase(getReviews.pending, function (state) {
            state.reviewsStatus = "loading";
        });
        builder.addCase(getReviews.fulfilled, function (state, action) {
            state.reviewsStatus = "idle";
            state.customerReviews = action.payload;
            state.hasMoreCustomerReviews = true;
        });
        builder.addCase(getReviews.rejected, function (state, action) {
            state.reviewsStatus = "failed";
            state.error = action.error.message;
        });
        builder.addCase(getNextReviews.pending, function (state) {
            state.reviewsStatus = "loading";
        });
        builder.addCase(getNextReviews.fulfilled, function (state, action) {
            state.reviewsStatus = "idle";
            if (action.payload) {
                state.customerReviews = __spreadArray(__spreadArray([], state.customerReviews, true), action.payload, true);
            }
            if (action.payload.length === 0) {
                state.hasMoreCustomerReviews = false;
            }
            else {
                state.hasMoreCustomerReviews = true;
            }
        });
        builder.addCase(getNextReviews.rejected, function (state, action) {
            state.reviewsStatus = "failed";
            state.error = action.error.message;
        });
    },
});
function populate(state, action) {
    if (action.payload) {
        if (action.payload.status) {
            state.partnerStatus = action.payload.status;
        }
        else {
            state.partnerStatus = "unregistered";
        }
        if (action.payload.id) {
            state.id = action.payload.id;
        }
        var details_2 = {};
        detailsFields.forEach(function (fieldName, index) {
            if (action.payload[fieldName] !== undefined) {
                if (!details_2)
                    details_2 = {};
                details_2[fieldName] = action.payload[fieldName];
            }
        });
        state.details = details_2;
        if (action.payload.imageUrl) {
            state.imageUrl = action.payload.imageUrl;
        }
        if (action.payload.pendingImageUrl) {
            state.pendingImageUrl = action.payload.pendingImageUrl;
        }
        if (action.payload.logoImageUrl) {
            state.logoImageUrl = action.payload.logoImageUrl;
        }
        var advance_1 = {};
        advanceFields.forEach(function (fieldName, index) {
            if (action.payload[fieldName] !== undefined) {
                if (!advance_1)
                    advance_1 = {};
                advance_1[fieldName] = action.payload[fieldName];
            }
        });
        state.advance = advance_1;
        state.adjustedDates = action.payload.adjustedDates;
        if (action.payload.pickupDays) {
            state.pickup = {
                pickupDays: __assign({}, action.payload.pickupDays),
            };
        }
        var pickup_1 = {};
        pickupFields.forEach(function (fieldName, index) {
            if (action.payload[fieldName] !== undefined) {
                if (!pickup_1)
                    pickup_1 = {};
                pickup_1[fieldName] = action.payload[fieldName];
            }
        });
        state.pickup = pickup_1;
        var delivery_1 = {};
        deliveryFields.forEach(function (fieldName, index) {
            if (action.payload[fieldName] !== undefined) {
                if (!delivery_1)
                    delivery_1 = {};
                delivery_1[fieldName] = action.payload[fieldName];
            }
        });
        state.delivery = delivery_1;
        var dineIn_1 = {};
        dineInFields.forEach(function (fieldName, index) {
            if (action.payload[fieldName] !== undefined) {
                if (!dineIn_1)
                    dineIn_1 = {};
                dineIn_1[fieldName] = action.payload[fieldName];
            }
        });
        state.dineIn = dineIn_1;
        if (action.payload['rating']) {
            state.rating = action.payload['rating'];
        }
        if (action.payload['numberOfRatings']) {
            state.numberOfRatings = action.payload['numberOfRatings'];
        }
        if (action.payload.trialEndDate) {
            state.trialEndDate = action.payload.trialEndDate;
        }
        if (action.payload.pauseDates) {
            state.pauseDates = action.payload.pauseDates;
        }
        if (action.payload.urlName) {
            state.urlName = action.payload.urlName;
        }
        if (action.payload.facebookUrl) {
            state.facebookUrl = action.payload.facebookUrl;
        }
        if (action.payload.tiktokUrl) {
            state.tiktokUrl = action.payload.tiktokUrl;
        }
        if (action.payload.instagramUrl) {
            state.instagramUrl = action.payload.instagramUrl;
        }
        if (action.payload.subscriptionPlan) {
            state.subscriptionPlan = action.payload.subscriptionPlan;
        }
        else {
            delete state.subscriptionPlan;
        }
        if (action.payload.subscriptionTrialClaimed) {
            state.subscriptionTrialClaimed = action.payload.subscriptionTrialClaimed;
        }
        else {
            state.subscriptionTrialClaimed = false;
        }
        if (action.payload.subscriptionStripeId) {
            state.subscriptionStripeId = action.payload.subscriptionStripeId;
        }
        else {
            delete state.subscriptionStripeId;
        }
    }
}
export var resetStatus = (_a = partnerSlice.actions, _a.resetStatus), resetMenuStatus = _a.resetMenuStatus, resetError = _a.resetError, setSubscriptionPlan = _a.setSubscriptionPlan, setPartnerStatus = _a.setPartnerStatus, reorderItem = _a.reorderItem, updateItem = _a.updateItem, updateArchiveItem = _a.updateArchiveItem, addItem = _a.addItem, deleteItem = _a.deleteItem, deleteArchiveItem = _a.deleteArchiveItem, archiveItem = _a.archiveItem, unarchiveItem = _a.unarchiveItem, moveItem = _a.moveItem, addCategory = _a.addCategory, deleteCategory = _a.deleteCategory, updateCategory = _a.updateCategory, reorderCategory = _a.reorderCategory, addModifier = _a.addModifier, updateModifier = _a.updateModifier, removeModifier = _a.removeModifier;
export default partnerSlice.reducer;
