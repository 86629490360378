"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdjustDateType = exports.deliveryFields = exports.pickupFields = exports.advanceFields = exports.dineInFields = exports.websiteDetailsFields = exports.detailsFields = exports.AdvanceDurationType = exports.Day = exports.MAX_PREPARATION_DURATION_MINS = exports.MAX_ADVANCE_DAYS = void 0;
exports.MAX_ADVANCE_DAYS = 100;
exports.MAX_PREPARATION_DURATION_MINS = 120;
var Day;
(function (Day) {
    Day[Day["Sunday"] = 0] = "Sunday";
    Day[Day["Monday"] = 1] = "Monday";
    Day[Day["Tuesday"] = 2] = "Tuesday";
    Day[Day["Wednesday"] = 3] = "Wednesday";
    Day[Day["Thursday"] = 4] = "Thursday";
    Day[Day["Friday"] = 5] = "Friday";
    Day[Day["Saturday"] = 6] = "Saturday";
})(Day = exports.Day || (exports.Day = {}));
var AdvanceDurationType;
(function (AdvanceDurationType) {
    AdvanceDurationType[AdvanceDurationType["Days"] = 0] = "Days";
    AdvanceDurationType[AdvanceDurationType["Hours"] = 1] = "Hours";
})(AdvanceDurationType = exports.AdvanceDurationType || (exports.AdvanceDurationType = {}));
exports.detailsFields = ["isRestaurant", "name", "nameLowercase", "description", "longDescription", "address", "maskedAddress", "hideExactAddress", "maskedGeoLoc", "_geoloc", "cuisines", "tags", "customerContactPhone", "customerContactEmail"];
exports.websiteDetailsFields = ["urlName", "facebookUrl", "instagramUrl", "tiktokUrl"];
exports.dineInFields = ["numberOfTables", "absorbPaymentFee", "autoConfirmDineInOrders"];
exports.advanceFields = ["preparationEnabled", "preparationDuration", "preparationDurationType", "advanceOrderDays", "preparationInMins"];
exports.pickupFields = ["offerPickup", "offerDineIn", "pickupDays"];
exports.deliveryFields = ["offerDelivery", "deliveryDays", "deliveryFee", "minDeliveryOrder", "deliveryAreas", "deliveryPlaceIds", "deliveryConfiguration"];
var AdjustDateType;
(function (AdjustDateType) {
    AdjustDateType[AdjustDateType["Unchanged"] = 0] = "Unchanged";
    AdjustDateType[AdjustDateType["Adjusted"] = 1] = "Adjusted";
    AdjustDateType[AdjustDateType["Closed"] = 2] = "Closed";
})(AdjustDateType = exports.AdjustDateType || (exports.AdjustDateType = {}));
